import React, { Component } from 'react'
import Note from './Note';

export default class NoteTree extends Component {
    render() {
        return this.props.notes.map((note) => (
            <Note key={note.id} note={note} />
        ));
    }
}

import React, { Component } from 'react';
import './App.css';
import PageContainer from './components/PageContainer';

class App extends Component {
  state = {
    notes: [
      {
        id: 1,
        title: 'Note A'
      },
      {
        id: 2,
        title: 'Note B'
      },
      {
        id: 3,
        title: 'Note C'
      }
    ]
  }

  render() {
    return (
      <div className="App">
        <PageContainer notes={this.state.notes} />
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react'

export class PageRoot extends Component {
    getStyle = () => {
        return {
            background: '#c1e1e1',
            textAlign: 'center',
            padding: '5px',
            color: '#112233'
        }
    }

    render() {
        return (
            <div style={ this.getStyle() }>
                <h1> Zing Notes </h1>
            </div>
        )
    }
}

export default PageRoot
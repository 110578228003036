import React, { Component } from 'react'

export class Note extends Component {
    render() {
        return (
            <div>
                <p> {this.props.note.title} </p>
            </div>
        )
    }
}

// PropTypes


export default Note

import React, { Component } from 'react'
import NoteEditor from './NoteEditor'
import PageRoot from './PageRoot'
import Sidebar from './Sidebar'

class PageContainer extends Component {
    render() {
        return (
            <div className="container">
                <PageRoot />
                <div className="container">
                    <Sidebar notes={this.props.notes} />
                    <NoteEditor />
                </div>
            </div>
        )
    }
}

export default PageContainer
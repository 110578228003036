import React, { Component } from 'react'
import NoteTree from './NoteTree'

export class Sidebar extends Component {
    getStyle = () => {
        return {
            background: '#d4d4d4',
            float: 'left',
            textAlign: 'left',
            borderRight: '2px solid blue',
            width: 'auto'
        }
    }

    render() {
        return (
            <div style={this.getStyle()}>
                <NoteTree notes={this.props.notes} />
            </div>
        )
    }
}

export default Sidebar
